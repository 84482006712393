import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { TableCell } from '@material-ui/core'
import renderBloks from '@renderBloks'

const useStyles = makeStyles((theme) => ({
  cell: ({ variant }) => ({
    border:
      variant === 'colHeading' || variant === 'sectHeading'
        ? '0px solid'
        : '1px solid #C4C4C4',
    fontSize:
      variant === 'colHeading' || variant === 'sectHeading'
        ? '16px'
        : '0.831rem',
    fontWeight:
      variant === 'colHeading' || variant === 'sectHeading' ? '700' : '300',
    textDecorationLine: 'unset',
    textAlign: variant === 'sectHeading' ? 'left' : 'center',
  }),
  image: {
    maxHeight: '80px',
    maxWidth: '16vw',
  },
}))

const Header = ({ blok }) => {
  const { image, input, variant, link } = blok
  const classes = useStyles({ variant })

  return (
    <SbEditable content={blok}>
      <TableCell
        className={classes.cell}
        align="center"
        component="th"
        scope="row"
      >
        {image?.filename && (
          <img className={classes.image} src={image.filename} alt={image.alt} />
        )}
        {input}
        {renderBloks(link)}
      </TableCell>
    </SbEditable>
  )
}

export default Header
